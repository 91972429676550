<template>
  <div style="height: 100%" class="d-flex flex-row justify-center align-center">
    <v-card class="ma-4" width="50%">
      <v-card-title style="width: 100%" class="ma-0 pa-0">
        <v-toolbar
          width="100%"
          height="50"
          :color="$colors[0]"
          class="text-white"
          dark
        >
          <span>Mise a jour Prix Fournisseur </span>
        </v-toolbar>
      </v-card-title>
      <div class="ma-4">
        <div class="d-flex flex-row">
          <div style="width: 50%" class="d-flex flex-column mx-2">
            <span
              style="font-size: 12px; font-weight: 500"
              :style="{ color: $colors[0] }"
              >Liste de fournisseurs</span
            >
            <v-select
              class="mt-2"
              placeholder="Fournisseur"
              style="width: 100%"
              :items="listFournisseurs"
              v-model="fournisseurModel"
              dense
              outlined
            ></v-select>
          </div>
          <div style="width: 50%" class="d-flex flex-column mx-2">
            <span
              style="font-size: 12px; font-weight: 500"
              :style="{ color: $colors[0] }"
              >Type Energie</span
            >
            <v-select
              class="mt-2"
              placeholder="Energie"
              style="width: 100%"
              :items="listEnergieComputed"
              v-model="energieModel"
              dense
              outlined
            ></v-select>
          </div>
        </div>
        <v-form ref="formCSV">
          <div
            v-if="!!fournisseurModel && !!energieModel"
            class="d-flex flex-column"
          >
            <div
              v-if="
                fournisseurModel == 'Gazel' ||
                (fournisseurModel == 'Mint Energie') ||
                (fournisseurModel == 'Total Horizon' &&
                  energieModel == 'elec') ||
                (fournisseurModel == 'ENGIE PRO' && energieModel == 'elec')
              "
              class="d-flex flex-column mx-2"
            >
              <span
                style="font-size: 12px; font-weight: 500"
                :style="{ color: $colors[0] }"
                >Liste options</span
              >
              <v-select
                class="mt-2"
                placeholder="Options"
                :items="listOptionsComputed"
                v-model="optionModel"
                :rules="[$rules.required]"
                dense
                outlined
              ></v-select>
            </div>
            <div
              v-if="fournisseurModel == 'ENGIE PRO' && energieModel == 'gaz'"
              class="d-flex flex-column mx-2"
            >
              <span
                style="font-size: 12px; font-weight: 500"
                :style="{ color: $colors[0] }"
                >Ans</span
              >
              <template>
                <v-select
                  class="mt-2"
                  placeholder="Options"
                  :items="ansOptionsGaz"
                  v-model="ans"
                  :rules="[$rules.required]"
                  dense
                  outlined
                ></v-select>
              </template>
            </div>
            <div class="d-flex flex-row" style="width: 100%">
              <div
                v-if="
                  fournisseurModel != 'OHM' &&
                  fournisseurModel != 'Dyneff' &&
                  fournisseurModel != 'ENGIE PRO' &&
                  fournisseurModel != 'Ekwateur' &&
                  fournisseurModel != 'JPME' &&  fournisseurModel != 'JPME(TEXT)'
                "
                class="d-flex flex-column mx-2"
                style="width: 50%"
              >
                <span
                  style="font-size: 12px; font-weight: 500"
                  :style="{ color: $colors[0] }"
                  >Type Offre</span
                >
                <v-text-field
                  class="mt-2"
                  v-model="typeOffreModel"
                  style="width: 100%"
                  prepend-inner-icon="mdi-tag"
                  placeholder="Type Offre"
                  dense
                  outlined
                ></v-text-field>
              </div>
              <div
                v-if="fournisseurModel == 'OHM' || fournisseurModel == 'JPME'  "
                class="d-flex flex-column mx-2"
                style="width: 50%"
              >
                <span
                  style="font-size: 12px; font-weight: 500"
                  :style="{ color: $colors[0] }"
                  >Fichier XLSB</span
                >
                <v-file-input
                  class="mt-2"
                  v-model="fileModel"
                  style="width: 100%"
                  prepend-inner-icon="mdi-folder-open"
                  prepend-icon=""
                  accept=".xlsb"
                  dense
                  placeholder="Fichier XLSB"
                  outlined
                ></v-file-input>
              </div>
              <div
                v-if="
                  fournisseurModel !== 'ENGIE PRO' &&
                  fournisseurModel !== 'OHM' &&
                  fournisseurModel !== 'Ekwateur' && fournisseurModel !== 'JPME'  && fournisseurModel !== 'JPME(TEXT)'
                "
                class="d-flex flex-column mx-2"
                style="width: 50%"
              >
                <span
                  style="font-size: 12px; font-weight: 500"
                  :style="{ color: $colors[0] }"
                  >Fichier CSV</span
                >
                <v-file-input
                  class="mt-2"
                  v-model="fileModel"
                  style="width: 100%"
                  prepend-inner-icon="mdi-folder-open"
                  prepend-icon=""
                  accept=".csv"
                  dense
                  placeholder="Fichier CSV"
                  outlined
                ></v-file-input>
              </div>
              <!-- Additional conditions for Ekwateur -->
              <div
                style="width: 100%"
                class="mx-2"
                v-if="fournisseurModel == 'Ekwateur'"
              >
                <v-textarea
                  v-model="ekwateurInputModel"
                  style="width: 100%"
                  class="mt-2 input"
                  placeholder="Facturé 2024 €/MWh

50,82 €

53,44 €

54,98 €

54,98 €

54,98 €

54,98 €

54,98 €

54,98 €

54,98 €

120,00 €

Facturé 2025 €/MWh

53,35 €

55,42 €

56,58 €

56,58 €

56,58 €

56,58 €

56,58 €

56,58 €

56,58 €

120,00 €"
                  :rules="[$rules.required]"
                ></v-textarea>
              </div>
              <!-- Additional conditions for ENGIE PRO -->
              <div
                style="width: 100%"
                class="mx-2"
                v-if="fournisseurModel == 'ENGIE PRO'"
              >
                <!-- For 'gaz' -->
                <div v-if="energieModel === 'gaz'">
                  <span
                    style="font-size: 12px; font-weight: 500"
                    :style="{ color: $colors[0] }"
                    >Part Fourniture</span
                  >
                  <v-textarea
                    v-model="fournitureInputText"
                    style="width: 100%"
                    class="mt-2 input"
                    :placeholder="
                      this.ans == '1ans'
                        ? `example \nP1 - jusqu’à 3 999 kWh 25,82 4,224 300,00\nP2 - de 4 000 à 29 999 kWh 29,76 2,877 300,00 \nP3 - plus de 30 000 kWh 45,03 3,688 600,00\n`
                        : `example \nP1 - jusqu’à 3 999 kWh 12,49 3,826 3,635 3,443 300,00\nP2 - de 4 000 à 29 999 kWh 16,43 3,960 3,762 3,564 300,00\nP3 - plus de 30 000 kWh 31,70 3,943 3,746 3,549 600,00`
                    "
                    :rules="[$rules.required]"
                  ></v-textarea>
                  <span
                    style="font-size: 12px; font-weight: 500"
                    :style="{ color: $colors[0] }"
                    >Part Acheminement</span
                  >
                  <v-textarea
                    v-model="acheminementInputText"
                    style="width: 100%"
                    class="mt-2 input"
                    placeholder="example 
P1 - jusquà3 999 kWh 3,52 3,323
P2 - de 4 000à 29 999 kWh 11,62 0,893
P3 - plus de30 000 kWh 11,62 0,893"
                    :rules="[$rules.required]"
                  ></v-textarea>
                  <span
                    style="font-size: 12px; font-weight: 500"
                    :style="{ color: $colors[0] }"
                    >Part Obligations
                  </span>
                  <v-textarea
                    v-model="obligationsInputText"
                    style="width: 100%"
                    class="mt-2 input"
                    placeholder="example 
Niveau 2 0,999 1,730 1,533 1,732 1,533 1,732"
                    :rules="[$rules.required]"
                  ></v-textarea>
                </div>

                <!-- Further conditions for 'elec' -->
                <div v-else-if="energieModel === 'elec'">
                  <!-- OptionModel conditions -->
                  <div v-if="optionModel === 'c4'">
                    <span
                      style="font-size: 12px; font-weight: 500"
                      :style="{ color: $colors[0] }"
                      >Ans</span
                    >
                    <template>
                      <v-select
                        class="mt-2"
                        placeholder="Options"
                        :items="ansOptionsElec"
                        v-model="ans"
                        :rules="[$rules.required]"
                        dense
                        outlined
                      ></v-select>
                    </template>
                    <span
                      style="font-size: 12px; font-weight: 500"
                      :style="{ color: $colors[0] }"
                      >Part Fourniture</span
                    >

                    <v-textarea
                      v-model="engieProFournitureElec"
                      style="width: 100%"
                      class="mt-2 input"
                      placeholder="example 'x an 25,01 - 12,314 9,324 7,837 7,131'"
                      :rules="[$rules.required]"
                    ></v-textarea>
                  </div>
                  <div v-else-if="optionModel === 'c5'">
                    <span
                      style="font-size: 12px; font-weight: 500"
                      :style="{ color: $colors[0] }"
                      >Ans</span
                    >
                    <template>
                      <v-select
                        class="mt-2"
                        placeholder="Options"
                        :items="ansOptionsElec"
                        v-model="ans"
                        :rules="[$rules.required]"
                        dense
                        outlined
                      ></v-select>
                    </template>
                    <span
                      style="font-size: 12px; font-weight: 500"
                      :style="{ color: $colors[0] }"
                      >Part Fourniture</span
                    >
                    <v-textarea
                      v-model="engieProFournitureElec"
                      style="width: 100%"
                      class="mt-2 input"
                      placeholder="example 'x 14,28 9,129 252,00 14,28 9,420 7,481 252,00' if ALL ROWS ARE SINGLE LINE , or copy full table if abo,hph,hcp cover multiple rows"
                      :rules="[$rules.required]"
                    ></v-textarea>
                  </div>
                </div>
              </div>

              <!-- Additional conditions for JPME -->
              <div
                style="width: 100%"
                class="mx-2"
                v-if="fournisseurModel == 'JPME(TEXT)'"
              >
                <v-textarea
                  v-model="JpmeInputModel"
                  style="width: 100%"
                  class="mt-2 input"
                  placeholder="Abonnement
(€/an) 3 kVa 6 kVa 9 kVa 12 kVa 15 kVa 18 kVa 24 kVa 30 kVa 36 kVa
Base 84 84 84 120 120 120 180 180 180
HPHC -- 120 120 180 180 180 240 240 240
2024 01/07/2024 01/08/2024 01/09/2024 01/10/2024 01/11/2024 01/12/2024
Base 94,77 96,06 99,16 101,76 107,45 108,95
HP 99,71 101,06 104,52 107,40 113,72 115,71
HC 77,38 78,23 80,48 82,46 86,09 85,89
2025 01/01/2025 01/02/2025 01/03/2025 01/04/2025 01/05/2025 01/06/2025
Base 109,50 110,31 105,01 102,69 97,54 95,21
HP 116,14 116,43 110,98 108,45 103,19 100,82
HC 89,39 89,79 85,16 83,51 78,18 75,54
Coef de capacité 12 mois 24 mois
Base 0,38821 0,29138
HP 0,52350 0,39443
HC 0,06044 0,03208"
                  :rules="[$rules.required]"
                ></v-textarea>
              </div>
              <!-- si pas d'autre ajout par la suite à laisser sinon enlever-->
              <div
                style="width: 50%"
                class="mx-2"
                v-if="
                  this.fournisseurModel == 'OHM' ||
                  this.fournisseurModel == 'Dyneff'
                "
              ></div>
            </div>
          </div>
        </v-form>
        <div
          v-if="!!fournisseurModel && !!energieModel"
          class="d-flex flex-row justify-end"
        >
          <v-btn class="white--text" :color="$colors[0]" @click="send()"
            >Envoyer</v-btn
          >
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import api from "@/services/api";
export default {
  name: "FormulaireCSV",
  data() {
    return {
      listFournisseurs: [
        "Dyneff",
        "Gazel",
        "OHM",
        "Total Horizon",
        "Ekwateur",
        "ENGIE PRO",
        "JPME",
        "JPME(TEXT)",
        "Mint Energie"
      ],
      listEnergie: ["gaz", "elec"],
      listOptions: ["c4", "c3", "c5"],
      ansOptionsGaz: ["1ans", "3ans"],
      ansOptionsElec: ["1ans", "2ans"],
      fournisseurModel: "",
      energieModel: "",
      optionModel: "",
      ans: "",
      fileModel: [],
      typeOffreModel: "",
      fournitureInputText: "",
      acheminementInputText: "",
      obligationsInputText: "",
      engieProFournitureElec: "",
      ekwateurInputModel: "",
      JpmeInputModel: "",
    };
  },
  computed: {
    listOptionsComputed: function () {
      if (this.fournisseurModel == "Gazel" || this.fournisseurModel == "Mint Energie") {
        return ["c3", "c4"];
      } else if (
        this.fournisseurModel == "Total Horizon" ||
        this.fournisseurModel == "OHM" ||
        this.fournisseurModel == "ENGIE PRO"
      ) {
        return ["c4", "c5"];
      }
    },
    listEnergieComputed: function () {
      if (this.fournisseurModel == "Dyneff") {
        return ["gaz"];
      } else if (
        this.fournisseurModel == "Gazel" ||
        this.fournisseurModel == "Mint Energie" ||
        this.fournisseurModel == "OHM" ||
        this.fournisseurModel == "JPME" || this.fournisseurModel == "JPME(TEXT)" 
      ) {
        return ["elec"];
      } else {
        return this.listEnergie;
      }
    },
  },
  methods: {
    processEngieProGazData(inputText1, inputText2, inputText3, ans) {
      function parseTariffData(inputText) {
        const lines = inputText
          .split("\n")
          .filter((line) => line.trim() !== "");
        const processedData = lines.map((line, index) => {
          const cleanedLine = line.substring(line.indexOf("kWh") + 3).trim();
          const values = cleanedLine
            .split(" ")
            .map((value) => value.replace(",", "."));
          const rowPrefix = 11 + index;
          return {
            [`Abo${index + 1}`]: parseFloat(values[0]),
            [`P${rowPrefix}`]: parseFloat(values[1]),
            [`P${rowPrefix}_10`]: parseFloat(values[ans == "3ans" ? 3 : 1]),
          };
        });
        return processedData;
      }

      function parseSplitTariffData(inputText) {
        const lines = inputText
          .replace(/\r\n/g, "\n")
          .split("\n")
          .filter((line) => line.trim() !== "");
        const combinedLines = lines.reduce((acc, line) => {
          if (!line.includes("kWh")) {
            acc[acc.length - 1] += " " + line;
          } else {
            acc.push(line);
          }
          return acc;
        }, []);

        return combinedLines.map((line) => {
          const cleanedLine = line.substring(line.indexOf("kWh") + 3).trim();
          const values = cleanedLine
            .split(" ")
            .map((value) => value.replace(",", "."));
          return parseFloat(values[0]);
        });
      }

      function mergeData(firstArray, additionalValues) {
        if (firstArray.length !== additionalValues.length) {
          throw new Error("The arrays do not have the same length.");
        }
        return firstArray.map((item, index) => {
          item[`Abo${index + 1}`] += additionalValues[index];
          return item;
        });
      }
      function parseAdditionalValues(inputText) {
        // Remove the initial descriptive part ("Niveau 1")
        const cleanedText = inputText
          .substring(inputText.indexOf("1") + 1)
          .trim();

        // Split the cleaned text by spaces and replace commas with dots for consistency
        const values = cleanedText
          .split(" ")
          .map((value) => value.replace(",", "."));

        // Extract specific values based on their positions
        const Add1 = parseFloat(values[0]) * 10;
        const Add2 = parseFloat(values[2]) * 10;
        const Add3 = parseFloat(values[4]) * 10;

        // Return an object with the extracted values
        return { Add1, Add2, Add3 };
      }
      try {
        // Parse the first input for Abo, P11, and P11_10 values
        const firstArray = parseTariffData(inputText1);

        // Parse the second input for additional Abo values
        const secondArray = parseSplitTariffData(inputText2);

        // Merge the first and second inputs
        const mergedArray = mergeData(firstArray, secondArray);

        // Parse the third input for Add1, Add2, and Add3 values
        const additionalValues = parseAdditionalValues(inputText3);

        // Combine all into a single object
        const finalObject = mergedArray.reduce(
          (acc, item, index) => {
            acc[`Abo${index + 1}`] = item[`Abo${index + 1}`];
            acc[`P01${index + 1}`] = item[`P${11 + index}`] * 10;
            acc[`P01${index + 1}_10`] = item[`P${11 + index}_10`] * 10;
            return acc;
          },
          { ...additionalValues, ans: this.ans }
        );
        Object.keys(finalObject).forEach((key) => {
          if (typeof finalObject[key] === "number") {
            finalObject[key] = Number(finalObject[key].toFixed(2));
          }
        });
        return finalObject;
      } catch (err) {
        alert("Error processing data , please double check your inputs");
        throw err;
      }
    },

    processEngieProElecData(inputText) {
      try {
        if (this.optionModel == "c5") {
          const isOldFormat = inputText.split("\n").length <= 2;

          if (isOldFormat) {
            let parts = inputText.split(" ");

            let abo = parseFloat(parts[1].replace(",", ".")).toFixed(2);
            let base = (parseFloat(parts[2].replace(",", ".")) * 10).toFixed(2);
            let abo_hp = parseFloat(parts[4].replace(",", ".")).toFixed(2);
            let hp = parseFloat(parts[5].replace(",", ".") * 10).toFixed(2);
            let hc = parseFloat(parts[6].replace(",", ".") * 10).toFixed(2);

            return {
              abo,
              base,
              abo_hp,
              hp,
              hc,
              base_r: base,
              hp_r: hp,
              hc_r: hc,
              annee: Number(this.ans.charAt(0)),
            };
          } else {
            // New format processing
            let lines = inputText
              .split("\n")
              .filter((line) => line.trim() !== "");

            let abo = parseFloat(
              lines[0].split(" ")[1].replace(",", ".")
            ).toFixed(2);
            let base = (parseFloat(lines[1].replace(",", ".")) * 10).toFixed(2);
            let abo_hp = parseFloat(
              lines[3].split(" ")[3].replace(",", ".")
            ).toFixed(2);
            let hp = parseFloat(
              lines[4].split(" ")[0].replace(",", ".") * 10
            ).toFixed(2);
            let hc = parseFloat(
              lines[4].split(" ")[1].replace(",", ".") * 10
            ).toFixed(2);

            return {
              abo,
              base,
              abo_hp,
              hp,
              hc,
              base_r: base,
              hp_r: hp,
              hc_r: hc,
              annee: Number(this.ans.charAt(0)),
            };
          }
        } else if (this.optionModel == "c4") {
          const parts = inputText.split(" ");

          let parsedValues = {
            abo: null,
            hph: null,
            hch: null,
            hpe: null,
            hce: null,
          };

          parsedValues.abo = parseFloat(parts[2].replace(",", ".")).toFixed(2);
          parsedValues.hph = (
            parseFloat(parts[4].replace(",", ".")) * 10
          ).toFixed(2);
          parsedValues.hch = (
            parseFloat(parts[5].replace(",", ".")) * 10
          ).toFixed(2);
          parsedValues.hpe = (
            parseFloat(parts[6].replace(",", ".")) * 10
          ).toFixed(2);
          parsedValues.hce = (
            parseFloat(parts[7].replace(",", ".")) * 10
          ).toFixed(2);

          return {
            ...parsedValues,
            type: this.ans,
            hph_r: parsedValues.hph,
            hch_r: parsedValues.hch,
            hpe_r: parsedValues.hpe,
            hce_r: parsedValues.hce,
          };
        }
      } catch (err) {
        alert("Error processing data , please double check your inputs");
        throw err;
      }
    },
    processEkwateurData(input) {
      if (this.energieModel == "gaz") {
        const lines = input.split("\n");
        let currentYear = null;
        let yearData = {};
        let results = [];
        let priceCount = 0;

        for (let line of lines) {
          // Detect and update the year
          if (line.includes("Facturé")) {
            if (currentYear !== null) {
              // Save the last year's data if exists
              delete yearData[`P0${11 + priceCount - 1}`]; // Remove the last P0XX as it's now 'abo'
              results.push({ year: currentYear, ...yearData });
            }
            currentYear = line.match(/\d+/)[0]; // Extracts the year number
            yearData = {};
            priceCount = 0; // Reset price count for new year
            continue;
          }

          // Process the line if it contains a price
          if (line.trim().length > 0 && line.includes("€")) {
            let price = parseFloat(
              line.trim().replace(",", ".").replace(" €", "")
            ); // Change comma to period and remove € symbol
            priceCount++;
            let code = `P0${11 + priceCount - 1}`;
            yearData[code] = price;
          }
        }

        if (currentYear !== null) {
          delete yearData[`P0${11 + priceCount - 1}`];
          results.push({ year: currentYear, ...yearData });
        }
        return results;
      } else if (this.energieModel == "elec") {
        const lines = input.split("\n");
        let currentYear = null;
        let yearData = {};
        let results = [];
        let priceCount = 0;

        const indexToField = {
          1: "BASE",
          2: "HP",
          3: "HC",
          // Skip index 4
          5: "HPH",
          6: "HCH",
          7: "HPE",
          8: "HCE",
        };

        for (let line of lines) {
          // Detect and update the year
          if (line.includes("Facturé")) {
            if (currentYear !== null) {
              // Save the last year's data if exists
              delete yearData[indexToField[priceCount]]; // Remove the last field as it's now 'abo'
              results.push({ year: currentYear, ...yearData });
            }
            currentYear = line.match(/\d+/)[0]; // Extracts the year number
            yearData = {};
            priceCount = 0; // Reset price count for new year
            continue;
          }

          // Process the line if it contains a price
          if (line.trim().length > 0 && line.includes("€")) {
            priceCount++;
            if (priceCount !== 4) {
              // Skip the 4th price
              let price = parseFloat(
                line.trim().replace(",", ".").replace(" €", "")
              );
              let fieldName = indexToField[priceCount];
              yearData[fieldName] = price;

              // If the current field is 'HPH', also assign its value to 'POINTE'
              if (fieldName === "HPH") {
                yearData["POINTE"] = price;
              }
            }
          }
        }

        // Ensure last year's data is also pushed with 'abo'
        if (currentYear !== null) {
          delete yearData[indexToField[priceCount]];
          results.push({ year: currentYear, ...yearData });
        }

        return results;
      }
    },
    processJPMETEXTData(input) {
      const lines = {
        dates: [],
        month_number: [12, 24],
        profiles: ["C5 Base", "C5 HPHC"],
      };

      const newPrices = {
        "Abonnement (€ /an)": {},
        "E-Fourniture C5 Pro": {},
        "Coef de capacité": {
          "12 mois": {},
          "24 mois": {},
        },
      };

      const datePattern = /(\d{2}\/\d{2}\/\d{4})/g;
      const kVaPattern = /(\d+ kVa)/g;
      const pricePattern = /(\d+(,\d{2})?)/g;

      const linesArray = input.split("\n");

      // Extracting dates
      const dateMatches = input.match(datePattern);
      if (dateMatches) {
        lines.dates = dateMatches;
      }

      // Extracting kVa values
      const kvaMatches = linesArray[1].match(kVaPattern);
      if (kvaMatches) {
        kvaMatches.forEach((kva) => {
          newPrices["Abonnement (€ /an)"][kva] = { Base: null, HPHC: null };
        });
      }

      // Extracting Abonnement prices
      let i = 2; // Starting from the line after kVa
      for (let j = 0; j < 2; j++) {
        // Base and HPHC
        const kvaPrices = linesArray[i + j].match(pricePattern);
        if (kvaPrices) {
          kvaPrices.forEach((price, index) => {
            const parsedPrice = parseFloat(price.replace(",", "."));
            if (j === 0) {
              newPrices["Abonnement (€ /an)"][kvaMatches[index]]["Base"] =
                parsedPrice;
            } else {
              newPrices["Abonnement (€ /an)"][kvaMatches[index]]["HPHC"] =
                parsedPrice;
            }
          });
        }
      }

      // Correct the 36 kVa value
      newPrices["Abonnement (€ /an)"]["36 kVa"]["HPHC"] = 240;

      // Extracting E-Fourniture C5 Pro prices for dynamic years
      const yearLines = linesArray.filter((line) => /^\d{4}/.test(line));
      yearLines.forEach((yearLine) => {
        const [year, ...dates] = yearLine.split(" ");
        newPrices["E-Fourniture C5 Pro"][year] = {};

        const basePrices = linesArray[linesArray.indexOf(yearLine) + 1]
          .split(" ")
          .slice(1)
          .map((price) => parseFloat(price.replace(",", ".")));
        const hpPrices = linesArray[linesArray.indexOf(yearLine) + 2]
          .split(" ")
          .slice(1)
          .map((price) => parseFloat(price.replace(",", ".")));
        const hcPrices = linesArray[linesArray.indexOf(yearLine) + 3]
          .split(" ")
          .slice(1)
          .map((price) => parseFloat(price.replace(",", ".")));

        if (
          dates.length &&
          basePrices.length &&
          hpPrices.length &&
          hcPrices.length
        ) {
          dates.forEach((date, idx) => {
            newPrices["E-Fourniture C5 Pro"][year][date] = {
              Base: basePrices[idx],
              HP: hpPrices[idx],
              HC: hcPrices[idx],
            };
          });
        }
      });

      // Extracting Coef de capacité
      const coefIndex = linesArray.findIndex((line) =>
        line.includes("Coef de capacité")
      );
      if (coefIndex !== -1) {
        const baseLine = linesArray[coefIndex + 1].split(" ");
        const hpLine = linesArray[coefIndex + 2].split(" ");
        const hcLine = linesArray[coefIndex + 3].split(" ");

        if (baseLine.length > 1 && hpLine.length > 1 && hcLine.length > 1) {
          newPrices["Coef de capacité"]["12 mois"] = {
            Base: parseFloat(baseLine[1].replace(",", ".")),
            HP: parseFloat(hpLine[1].replace(",", ".")),
            HC: parseFloat(hcLine[1].replace(",", ".")),
          };
          newPrices["Coef de capacité"]["24 mois"] = {
            Base: parseFloat(baseLine[2].replace(",", ".")),
            HP: parseFloat(hpLine[2].replace(",", ".")),
            HC: parseFloat(hcLine[2].replace(",", ".")),
          };
        }
      }

      return { lines, newPrices };
    },

    async send() {
      if (this.$refs.formCSV.validate()) {
        const token = this.$store.getters.getToken || null;
        const formData = new FormData();
        formData.append("fournisseur", this.fournisseurModel);
        formData.append("energie_type", this.energieModel);
        formData.append("type_compteur", this.optionModel);
        formData.append("file", this.fileModel);
        formData.append("typeOffre", this.typeOffreModel);
        formData.append("token", token);
        if (
          this.fournisseurModel == "ENGIE PRO" &&
          this.energieModel == "gaz"
        ) {
          if (
            !this.fournitureInputText ||
            !this.acheminementInputText ||
            !this.obligationsInputText ||
            !this.ans
          ) {
            alert("All fields must be filled out before submitting.");
            return;
          }

          formData.append(
            "engie_pro_fields",
            JSON.stringify(
              this.processEngieProGazData(
                this.fournitureInputText,
                this.acheminementInputText,
                this.obligationsInputText,
                this.ans
              )
            )
          );
        } else if (
          this.fournisseurModel == "ENGIE PRO" &&
          this.energieModel == "elec"
        ) {
          formData.append(
            "engie_pro_fields",
            JSON.stringify(
              this.processEngieProElecData(this.engieProFournitureElec)
            )
          );
        } else if (this.fournisseurModel == "Ekwateur") {
          formData.append(
            "ekwateur_fields",
            JSON.stringify(this.processEkwateurData(this.ekwateurInputModel))
          );
        } 
        else if (this.fournisseurModel == "JPME(TEXT)") {
        
          formData.append(
            "jpme_fields",
            JSON.stringify(this.processJPMETEXTData(this.JpmeInputModel))
          );
        }


        let endpoint;

        if (this.fournisseurModel === "Mint Energie") {
          if (this.optionModel === "c3") {
            endpoint = "savedataMintEnergieC3/";
          } else if (this.optionModel === "c4") {
            endpoint = "savedataMintEnergieC4/";
          }
        } else {
          endpoint = "updatePrixFournisseur/";
        }
        let response;
        if (endpoint) {
          response = await api.post(endpoint, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }
        // handle the response from the backend
        if (response.status == 200) {
          if (response.data.result == true) {
            alert("Updated successfully");
            // clear the form
            this.clear();
          } else {
            alert("Update failed");
          }
        }
      } else {
        console.log("error");
      }
    },

    clear() {
      this.fournisseurModel = "";
      this.energieModel = "";
      this.optionModel = "";
      this.fileModel = [];
      this.typeOffreModel = "";
      this.ekwateurInputModel = "";
      this.fournitureInputText = "";
      this.acheminementInputText = "";
      this.obligationsInputText = "";
      this.engieProFournitureElec = "";
      this.ans = "";
    },
  },
};
</script>
